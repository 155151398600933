const hexa_data = {
  origin_core_sol: [
    5, 6, 7, 8, 10, 12, 14, 17, 20, 30, 33, 36, 40, 44, 48, 52, 56, 60, 65, 80,
    85, 90, 95, 100, 105, 111, 117, 123, 130, 150,
  ],
  origin_core_sol_frag: [
    100, 130, 165, 205, 250, 300, 355, 415, 480, 680, 760, 850, 950, 1060, 1180,
    1310, 1450, 1600, 1760, 2110, 2280, 2460, 2650, 2850, 3060, 3280, 3510,
    3750, 4000, 4500,
  ],
  mastery_core_sol: [
    3, 4, 5, 6, 7, 8, 9, 11, 13, 18, 20, 22, 24, 26, 28, 30, 32, 34, 37, 45, 48,
    51, 54, 57, 60, 63, 66, 69, 73, 83,
  ],
  mastery_core_sol_frag: [
    50, 65, 83, 103, 126, 151, 179, 209, 242, 342, 382, 427, 477, 532, 592, 657,
    727, 802, 882, 1057, 1142, 1232, 1327, 1427, 1532, 1642, 1757, 1877, 2002,
    2252,
  ],
  v_core_sol: [
    4, 5, 6, 7, 9, 11, 13, 16, 19, 27, 30, 33, 36, 39, 42, 45, 48, 51, 55, 67,
    71, 75, 79, 83, 87, 92, 97, 102, 108, 123,
  ],
  v_core_sol_frag: [
    75, 98, 125, 155, 189, 227, 269, 314, 363, 513, 573, 641, 716, 799, 889,
    987, 1092, 1205, 1325, 1588, 1716, 1851, 1994, 2144, 2302, 2467, 2640, 2820,
    3008, 3383,
  ],
  public_core_sol: [
    7, 9, 11, 13, 16, 19, 22, 27, 32, 46, 51, 56, 62, 68, 74, 80, 86, 92, 99,
    116, 123, 130, 137, 144, 151, 160, 169, 178, 188, 208,
  ],
  public_core_sol_frag: [
    125, 163, 207, 257, 314, 377, 446, 521, 603, 903, 1013, 1137, 1275, 1427,
    1592, 1771, 1964, 2171, 2391, 2916, 3150, 3398, 3660, 3935, 4224, 4527,
    4844, 5174, 5518, 6268,
  ],
};
export default hexa_data;
